import { useState } from 'react';
import Slider, { } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

const RangeSlider = () => {
  const [range, setRange] = useState([3, 100]);

  const handleChange = (value) => {
    setRange(value);
  };

  const mapping = {
    0:"3",
    1:"3.5",
    2:"4",
    3:"4.5",
    4:"5",
    5:"5.5",
    6:"6",
    7:"6.5",
    8:"7",
    9:"7.5",
    10:"8",
    11:"8.5",
    12:"9",
    13:"9.5",
    14:"10",
    15:"11",
    16:"12",
    17:"13",
    18:"14",
    19:"15",
    20:"20",
    21:"25",
    22:"25",
    23:"30",
    24:"40",
    25:"50",
  }

  return (
    <div style={{ width: 400, margin: 50 }}>

      <Slider
        range
        min={0}
        max={25}
        defaultValue={range}
        onChange={handleChange}
        allowCross={false}
        railStyle={{ backgroundColor: '#d9d9d9' }} // レールのスタイル
        trackStyle={{ backgroundColor: '#ff5733' }} // トラックのスタイル
        handleStyle={[
          { borderColor: '#ff5733', backgroundColor: '#ffffff' }, // ハンドルのスタイル
          { borderColor: '#ff5733', backgroundColor: '#ffffff' }
        ]}
      />
      <div style={ {width: "100%"}}></div>
      <hr style={ {marginTop: "50px"} } />
      <div>家賃: { range[0] > 0 ? `${mapping[range[0]]}万円` :"下限なし"} ～ { range[1] < 25 ? `${mapping[range[1]]}万円` : "上限なし" }</div>
      <input type='hidden' name='min' value={range[0]} /> 
      <input type='hidden' name='max' value={range[1]} /> 
    </div>
  );
};

export default RangeSlider;
